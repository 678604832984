.App {
  text-align: center;
}

.Sprite {
  /* IE, only works on <img> tags */
  -ms-interpolation-mode: nearest-neighbor !important;
  /* Firefox */
  image-rendering: crisp-edges !important;
  /* Chromium + Safari */
  image-rendering: pixelated !important;


}

.react-responsive-spritesheet-container {
  /* transform: scale(2) !important; */
  scale: 40% !important;
  position: relative;
  left: 30%;
  top: 20%;

}

#root {
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
